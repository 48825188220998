x-header-lang[container]
  +preset(4)
  display: flex
  padding: 14px 16px

x-header-lang[item]
  cursor: pointer
  color: var(--on-primary-dark)
  height: 16px
  line-height: 16px
  &[selected],
  &:hover
    color: var(--on-primary-green)
  & + &
    margin-left: 12px
    border-left: 1px solid var(--divider-dark-grey)
    padding-left: 12px
